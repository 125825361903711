import React, { useState, useEffect } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
// components
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import DrivingSchoolCard from "../components/drivingschoolcard";
import HomeVideoBackground from "../components/homevideobackground";
import Track from "../components/track";
import settings from "../../settings";

// images
const poster = `${settings.IMAGES_BASE_URL}/images/home/poster_home.webp`;
const poster1200 = `${settings.IMAGES_BASE_URL}/images/home/poster_home-1200.webp`;
const poster800 = `${settings.IMAGES_BASE_URL}/images/home/poster_home-800.webp`;
const poster400 = `${settings.IMAGES_BASE_URL}/images/home/poster_home-400.webp`;

const teenschool = `${settings.IMAGES_BASE_URL}/images/home/bmw-teen-school.jpg`;
const teenschool1 = `${settings.IMAGES_BASE_URL}/images/home/bmw-teen-school-400.webp`;
const teenschool2 = `${settings.IMAGES_BASE_URL}/images/home/bmw-teen-school-800.webp`;
const teenschool3 = `${settings.IMAGES_BASE_URL}/images/home/bmw-teen-school-1200.webp`;
const driverschool = `${settings.IMAGES_BASE_URL}/images/home/bmw_driver_school.jpg`;
const driverschool1 = `${settings.IMAGES_BASE_URL}/images/home/bmw_driver_school-400.webp`;
const driverschool2 = `${settings.IMAGES_BASE_URL}/images/home/bmw_driver_school-800.webp`;
const driverschool3 = `${settings.IMAGES_BASE_URL}/images/home/bmw_driver_school-1200.webp`;
const mschool = `${settings.IMAGES_BASE_URL}/images/home/bmw_m_school.jpg`;
const mschool1 = `${settings.IMAGES_BASE_URL}/images/home/bmw_m_school-400.webp`;
const mschool2 = `${settings.IMAGES_BASE_URL}/images/home/bmw_m_school-800.webp`;
const mschool3 = `${settings.IMAGES_BASE_URL}/images/home/bmw_m_school-1200.webp`;
const indyTeaser = `${settings.IMAGES_BASE_URL}/images/home/indy-teaser.jpg`;
const indyTeaser1 = `${settings.IMAGES_BASE_URL}/images/home/indy-teaser-400.webp`;
const indyTeaser2 = `${settings.IMAGES_BASE_URL}/images/home/indy-teaser-800.webp`;
const indyTeaser3 = `${settings.IMAGES_BASE_URL}/images/home/indy-teaser-1200.webp`;
const homeVideo = `${settings.IMAGES_BASE_URL}/videos/home-video-opening-2022.mp4`;
const pgaWestBanner = `${settings.IMAGES_BASE_URL}/images/home/pga-west-home-banner.jpg`;
const pgaWestBanner1 = `${settings.IMAGES_BASE_URL}/images/home/pga-west-home-banner-400.webp`;
const pgaWestBanner2 = `${settings.IMAGES_BASE_URL}/images/home/pga-west-home-banner-800.webp`;
const pgaWestBanner3 = `${settings.IMAGES_BASE_URL}/images/home/pga-west-home-banner-1200.webp`;
const mMixedRealityBanner = `${settings.IMAGES_BASE_URL}/images/home/m-mixed-reality-home-banner.jpg`;
const mMixedRealityBanner1 = `${settings.IMAGES_BASE_URL}/images/home/m-mixed-reality-home-banner-400.webp`;
const mMixedRealityBanner2 = `${settings.IMAGES_BASE_URL}/images/home/m-mixed-reality-home-banner-800.webp`;
const mMixedRealityBanner3 = `${settings.IMAGES_BASE_URL}/images/home/m-mixed-reality-home-banner-1200.webp`;
const holidayBanner = `${settings.IMAGES_BASE_URL}/images/home/bmw-holiday.jpg`;
const holidayBanner1 = `${settings.IMAGES_BASE_URL}/images/home/bmw-holiday-400.webp`;
const holidayBanner2 = `${settings.IMAGES_BASE_URL}/images/home/bmw-holiday-800.webp`;
const holidayBanner3 = `${settings.IMAGES_BASE_URL}/images/home/bmw-holiday-1200.webp`;
const carsCoffeeBanner = `${settings.IMAGES_BASE_URL}/images/home/toy-drive-home-banner.jpg`;
const carsCoffeeBanner1 = `${settings.IMAGES_BASE_URL}/images/home/toy-drive-home-banner-400.webp`;
const carsCoffeeBanner2 = `${settings.IMAGES_BASE_URL}/images/home/toy-drive-home-banner-800.webp`;
const carsCoffeeBanner3 = `${settings.IMAGES_BASE_URL}/images/home/toy-drive-home-banner-1200.webp`;


const IndexPage = () => {
  const [currentCard, setCurrentCard] = React.useState(null);
  const today = new Date();
  const showCarsCoffeeBanner = 
      today >= new Date(2024, 10, 8) &&
      today < new Date("2024-12-07T11:00:00-08:00");

  React.useEffect(() => {
    const today = new Date();
    const showHolidayBanner = 
      today >= new Date(2024,10,8) && 
      today < new Date(2025,0,1);
    const mMixed = today > new Date("2024-10-27T17:00:00-07:00");

    if (showHolidayBanner) {
      setCurrentCard(getHolidayCard());
    } else if (mMixed) {
      setCurrentCard(getmMixedCard());
    }
  }, []);

  const getHolidayCard = () => {
    return (
      <>
        <FlexCard
          rowType="row"
          image={holidayBanner}
          imageAlt="TURBOCHARGE YOUR HOLIDAY"
          header="TURBOCHARGE YOUR HOLIDAY"
          subheader="20% OFF A GIFT CARD"
          text="Create the perfect holiday with some M Power. Get 20% off a gift card, good toward many classes and Experiences."
          btnText="CALL 888-345-4269 TO BOOK"
          btnStyle="btn-blue-offers"
          phone="tel:888-345-4269"
          srcSet={holidayBanner1 + " 400w, "+ holidayBanner2+" 800w, " + holidayBanner3+" 1200w"  }
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
        >
          <p>
            Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class today with promo code <span className="bold">24BMWHPH20.</span>. 
          </p>
          <p>
            Must purchase gift card by 12/31/24. Not valid for M4 GT4 experience, Race License School, M Mixed Reality or Private Instruction. Valid at our South Carolina, California and Indianapolis locations.
          </p>
        </FlexCard>
      </>
    )
  } 

  const getmMixedCard = () => {
    return (
      <>
        <FlexCard
          rowType="row"
          image={mMixedRealityBanner}
          imageAlt="WELCOME TO THE VIRTUAL REAL WORLD"
          header="WELCOME TO THE VIRTUAL REAL WORLD"
          subheader="M MIXED REALITY IS HERE"
          text="Hop into a BMW M car, slip on a headset, and drive it through a virtual track. This isn’t fantasy, and this isn’t reality. It’s both."
          btnText="Learn More"
          btnStyle="btn-blue"
          route="/experiences"
          srcSet={mMixedRealityBanner1 + " 400w, "+ mMixedRealityBanner2+" 800w, " + mMixedRealityBanner3+" 1200w"  }
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
        >
          <p>
            M Mixed Reality is led by professional driving instructors on a closed course, with customized vehicle technology specific to this experience.
          </p>
        </FlexCard>
      </>
    )
  }

  const secondCard = () => {
    if(showCarsCoffeeBanner) {
      return (
        <FlexCard
          rowType="row-reverse"
          image={carsCoffeeBanner}
          imageAlt="CARS, COFFEE AND HOT LAPS TOY DRIVE"
          header="CARS, COFFEE AND HOT LAPS TOY DRIVE"
          subheader="DECEMBER 7, 2024 IN THERMAL, CALIFORNIA"
          srcSet={carsCoffeeBanner1 + " 400w, "+ carsCoffeeBanner2+" 800w, " + carsCoffeeBanner3+" 1200w"  }
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
          btnText="Learn More"
          btnStyle="btn-blue"
          route="/experiences"
          text="Register for our Cars, Coffee and Hot Laps Toy Drive, bring an unwrapped toy, and you’ll be given a free hot lap with one of our pro instructors in a BMW M car."
        >
          <p>
            All toys go to Toys For Tots.
          </p>
        </FlexCard>
      )
    } else {
      return (
        <FlexCard
          rowType="row-reverse"
          image={indyTeaser}
          imageAlt="ROARING OUT OF THE INDY GARAGE"
          header="ROARING OUT OF THE INDY GARAGE"
          subheader="FALL 2025 DATES BOOKING SOON"
          srcSet={indyTeaser1 + " 400w, "+ indyTeaser2+" 800w, " + indyTeaser3+" 1200w"  }
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
          text="Prepare for more thrills at the Indianapolis Motor Speedway this coming fall. Expect M car excitement around one of the country’s most famous tracks."
        />
      )
    }
  }


  return (
    <Layout>
      <SEO title="BMW Performance Driving School" description="Learn total car control behind the wheel of a BMW at the BMW Performance Driving School. Multiple class offerings and challenges await in one of our three locations." />
      <div className="homepage">
        <HomeVideoBackground
          url={homeVideo}
          poster={poster}
          srcSet={`${poster400} 400w, ${poster800} 800w, ${poster1200} 1200w, ${poster} 1920w`}
          sizes="100vw"
          hideMobileHeader={true}
          topLeftChildren={
            <div className="text_left">
              <div>DRIVE ON THE EDGE OF PHYSICS.</div>
            </div>
          }
        />
        <section className="performance-center">
          <div className="container">
            <div className="drivingschool__header">
              <h1>THE BMW PERFORMANCE DRIVING SCHOOL</h1>
              <p>
                An open track, the Ultimate Driving Machine, and you in the driver’s seat. <br/>
                Prepare for a BMW driving experience like no other.
              </p>
            </div>
          </div>
          {currentCard}
          {secondCard()}
          <FlexCard
            rowType="row"
            image={pgaWestBanner}
            imageAlt="SPEED TIME GOES WITH TEE TIME."
            header="SPEED TIME GOES WITH TEE TIME."
            subheader="PGA WEST DISCOUNT"
            srcSet={pgaWestBanner1 + " 400w, "+ pgaWestBanner2+" 800w, " + pgaWestBanner3+" 1200w"  }
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
            text="Book a round of golf at the exclusive PGA WEST for a discounted rate when you book a class at our Thermal location. Be on the lookout for a link in your confirmation email for more details."
          />
          {/* <FlexCard
            rowType="row"
            image={bimmerbeliever}
            imageAlt="BIMMER BELIEVER"
            header="BIMMER BELIEVER"
            subheader="BMW ACCESSORIES AND LIFESTYLE"
            text="Whether it’s an M pen or M Power, you’ll find excitement waiting to be added with Genuine BMW Accessories at ShopBMWUSA.com."
            btnText="Shop now"
            btnStyle="btn-blue"
            externalURL="http://www.shopbmwusa.com?utm_source=bmwperformancecentercom&utm_medium=FMAbanner&utm_campaign=AFTERfebmar"
          /> */}
        </section>
        <Track variant="white" />
        <section className="drivingschool">
          <div className="container">
            <div className="drivingschool__header">
              <h2>BMW DRIVING SCHOOLS</h2>
            </div>
          </div>
          <DrivingSchoolCard
            rowType="row"
            image={teenschool}
            imageAlt="BMW Teen School 01"
            header="BMW TEEN SCHOOL"
            subheader="UNDERSTANDING THE BASICS"
            text="Leave your perception of driver’s education class at the door: this isn’t about parallel parking. Teens will learn total car control at the limit on our closed track, making them much more alert and aware. LOLs are encouraged."
            learnUrl="/teenschool"
            learnBtnStyle="btn-bordered"
            bookBtnStyle="btn-blue"
            bookUrl="https://pds.eventsbmw.com/?brand=BMW&class=BMW_One_Day_Teen_School"
            srcSet={teenschool1 + " 400w, "+ teenschool2+" 800w, " + teenschool3+" 1200w"  }
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
          />
          <DrivingSchoolCard
            rowType="row"
            image={driverschool}
            imageAlt="BMW Driver's School"
            header="BMW DRIVER’S SCHOOL"
            subheader="A THRILLING BMW DRIVING EXPERIENCE"
            text="Let’s bring you up to speed on the best ways to handle a BMW’s advanced capabilities with classes that focus on car control, proper vision, brake points and more. If your heart rate just went up, this is the class for you."
            learnUrl="/driverschool"
            learnBtnStyle="btn-bordered"
            bookBtnStyle="btn-blue"
            bookUrl="https://pds.eventsbmw.com/?brand=BMW&class=BMW_One_Day_School"
            srcSet={driverschool1 + " 400w, "+ driverschool2+" 800w, " + driverschool3+" 1200w"  }
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
          />
          <DrivingSchoolCard
            rowType="row"
            image={mschool}
            imageAlt="BMW M School"
            header="BMW M SCHOOL"
            subheader="IT’S PRACTICALLY A BMW RACE SCHOOL"
            text="Unleash the full potential of our M vehicles as you push them through high-speed stability, control and drifting exercises. Prepare for the ultimate drive."
            learnUrl="/mschool"
            learnBtnStyle="btn-bordered"
            bookBtnStyle="btn-blue"
            bookUrl="https://pds.eventsbmw.com/?brand=M&class=One_Day_M_School"
            srcSet={mschool1 + " 400w, "+ mschool2+" 800w, " + mschool3+" 1200w"  }
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
          />
        </section>
      </div>
    </Layout>
  );
}

export default IndexPage;
