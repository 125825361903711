import React,{forwardRef} from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import { LazyLoadImage } from "react-lazy-load-image-component";

import settings from "../../settings";
import TrackItem from "../components/trackitem";
import useWindowSize from "../hooks/useWindowSize";

const spartanburg = `${settings.IMAGES_BASE_URL}/images/home/spartanburg-overhead-v2-track.jpg`;
const thermalca = `${settings.IMAGES_BASE_URL}/images/home/thermal-overhead-v2-track.jpg`;

const spartanburgTitle = "Spartanburg, South Carolina";
const spartanburgText = "Fast turns, a back straight perfect for speed exercises and a polished wet concrete skid pad make our Spartanburg track the perfect place to hone your driving skills.";
const thermalCaTitle = "Thermal, California";
const thermalCaText =
  "Set against the backdrop of a beautiful mountain range, Thermal offers a unique track layout with the same challenges as our East Coast location.";



const Track = forwardRef((props, ref) => {
  const { variant, titleClassName } = props;

  const { width } = useWindowSize();

  const isMobile = width < 1200;

  if (variant === "black" && !isMobile) {
    return (
      <section className="track" ref={ref}>
        <div className="container">
          <h2 className="track__header-text">TWO TRACKS. ONE GOAL.</h2>
        </div>
        <div>
          <div className="row track__gap">
            <LazyLoadImage
              effect="blur"
              src={spartanburg}
              alt={spartanburgTitle}
            />
            <LazyLoadImage
              effect="blur"
              src={thermalca}
              alt={thermalCaTitle}
            />
          </div>

          <div
            className="row track__gap"
            style={{ position: "absolute", bottom: 0 }}
          >
            <div className="track__content track__full-width">
              <div
                className={classNames("track__text track__text_static", {
                  track__text_white: variant === "white"
                })}
              >
                <h3 className={titleClassName}>{spartanburgTitle}</h3>
                <p>{spartanburgText}</p>
              </div>
            </div>
            <div className="track__content track__full-width">
              <div
                className={classNames("track__text track__text_static", {
                  track__text_white: variant === "white"
                })}
              >
                <h3 className={titleClassName}>{thermalCaTitle}</h3>
                <p>{thermalCaText}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="track" ref={ref}>
      <div className="container">
        <h2 className="track__header-text">TWO TRACKS. ONE GOAL.</h2>
      </div>
      {isMobile ? (
        <div>
          <div className="col">
            <TrackItem
              image={spartanburg}
              variant={variant}
              title={spartanburgTitle}
              titleClassName={titleClassName}
              text={spartanburgText}
            />
          </div>
          <div className="col">
            <TrackItem
              image={thermalca}
              variant={variant}
              title={thermalCaTitle}
              titleClassName={titleClassName}
              text={thermalCaText}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="row track__gap">
            <LazyLoadImage
              effect="blur"
              src={spartanburg}
              alt={spartanburgTitle}
            />
            <LazyLoadImage effect="blur" src={thermalca} alt={thermalCaTitle} />
          </div>

          <div className="row track__gap">
            <div className="track__content track__full-width">
              <div
                className={classNames("track__text", {
                  track__text_white: variant === "white"
                })}
              >
                <h3 className={titleClassName}>{spartanburgTitle}</h3>
                <p>{spartanburgText}</p>
              </div>
            </div>
            <div className="track__content track__full-width">
              <div
                className={classNames("track__text", {
                  track__text_white: variant === "white"
                })}
              >
                <h3 className={titleClassName}>{thermalCaTitle}</h3>
                <p>{thermalCaText}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
});

Track.defaultProps = {
  variant: "black",
  titleClassName: ""
};

Track.propTypes = {
  variant: PropTypes.string,
  titleClassName: PropTypes.string
};

export default Track;
